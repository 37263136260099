<template>
    <div class="hardClassDialog">
        <el-dialog :title="myTitle" width="40%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :model="hardClassForm" ref="hardClassDialogForm" :rules="hardClassRules">

                <el-form-item label="班级名称"  prop="name" :label-width="formLabelWidth">
                    <el-input v-model="hardClassForm.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="入学年"  prop="year" :label-width="formLabelWidth">
                    <el-select v-model="hardClassForm.year" filterable placeholder="请选择">
                        <el-option v-for="item in yearList" :key="item" :label="item" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属单位"  prop="schoolId" :label-width="formLabelWidth">
                    <el-select v-model="hardClassForm.schoolId" filterable placeholder="请选择">
                        <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属专业"  prop="professionId" :label-width="formLabelWidth">
                    <el-select v-model="hardClassForm.professionId" filterable placeholder="请选择">
                        <el-option v-for="item in professionList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="备注"  prop="remark" :label-width="formLabelWidth">
                    <el-input v-model="hardClassForm.remark" type="textarea"></el-input>
                </el-form-item>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" :loading="loading" @click="submitForm">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'hardClassDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false,
                schoolList: [], professionList: [], yearList: [],
                hardClassForm:{ id: null, licenceId: null,  name: null, year: null, schoolId: null, professionId: null,remark: null,  },
                hardClassRules: {
                    name: [{ required: true, message: '请输入行政班名称', trigger: 'blur' }],
                    year: [{ required: true, message: '请输入入学年份', trigger: 'blur' }],
                    schoolId: [{ required: true, message: '请选择单位', trigger: 'blur' }],
                    professionId: [{ required: true, message: '请选择专业', trigger: 'blur' }],
                },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {
            this.loadYearList();
            this.loadSchoolList();
            this.loadProfessionList();
	    },
        props: {
            hardClass: {
                type: Object
            }
        },
        watch: {
            hardClass: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.hardClassForm = { id: n.id, licenceId: n.licence_id, name: n.name, year: n.year, schoolId: n.school_id,
                            professionId: n.profession_id, remark: n.remark };
                        if(n.id == null) this.myTitle = '新建行政班'; else this.myTitle = '编辑行政班';
                    }else{
                        this.hardClassForm = { id: null, licenceId: null,  name: null, year: null, schoolId: null, professionId: null,remark: null, };
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            loadYearList(){
                this.acadyearTermList = [];
                let now = new Date();
                let year = parseInt(now.getFullYear());
                for(let i=(year-5); i<(year+2); i++){
                    this.yearList.unshift(""+i);
                }
            },
            submitForm(){
                console.log(this.hardClassForm);
                let that = this;
                this.$refs.hardClassDialogForm.validate((valid) => {
                    if(valid){
                        this.loading = true;
                        this.hardClassForm.licenceId = this.userInfo.licenceId;
                        let param = { controllerName: 'sclHardClass', methodName: '/saveOrUpdate', param: this.hardClassForm};

                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            console.log('hardClass', code);
                            if (code == 0) {
                                this.dialogVisible = false;
                                that.$emit('ok');
                            }else if(code == -3){
                                this.$message({message: '该行政班已在系统中。。', type: 'error', duration: 5000});
                            }
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });
            },
            loadSchoolList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'school', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.schoolList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
            loadProfessionList(){

                let search = { licenceId: this.userInfo.licenceId};
                let param = { controllerName: 'sclProfession', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.professionList = data.result;
                    }
                }).catch((error) => {
                    console.log("error")
                });
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .hardClassDialog{

    }


</style>


